import { makeStyles } from '@material-ui/core/styles';

export const useTabStyles = makeStyles({
  tab: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '@media (max-width: 800px)': { gridTemplateColumns: '1fr' },
  },
  formTab: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '10px',
    '@media (max-width: 800px)': { gridTemplateColumns: '1fr' },
  },
});
