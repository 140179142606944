import { ExcludeProducts, FirstTypeCardsPatientOutsideNormZoneEnum } from '../api/generated';

export const outsideNormZoneSymbolMapping: Record<FirstTypeCardsPatientOutsideNormZoneEnum, string> = {
  [FirstTypeCardsPatientOutsideNormZoneEnum.Greater]: '↑',
  [FirstTypeCardsPatientOutsideNormZoneEnum.Lower]: '↓',
};

// export const analysisKindsChoices = Object.values(AnalysisKindEnum)
//   .filter((kind) => kind !== AnalysisKindEnum.Custom)
//   .map((analysisKind) => ({
//     id: analysisKind,
//     name: analysisKind,
//   }));

export const productsMapping: Record<keyof ExcludeProducts, string> = {
  oils: 'Масла',
  vegetables: 'Овощи',
  fruits: 'Фрукты',
  animalProtein: 'Животный белок',
  vegetableProtein: 'Растительный белок',
  nutsAndSeeds: 'Орехи и семена',
  cereals: 'Хлопья',
  milkProducts: 'Молочные продукты',
  drinks: 'Напитки',
  nutritionalSupplements: 'Пищевые добавки',
  sweets: 'Конфеты',
  condiments: 'Приправы',
  sweeteners: 'Подсластитель',
};

export enum LanguagesEnum {
  ru = 'ru',
  en = 'en',
}
