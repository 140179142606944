import { FC } from 'react';
import {
  ArrayInput,
  Edit,
  EditProps,
  FormTab,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin';
import { getErrorMessage } from 'utils/errors-messages';
import { nutritionPlanValidator } from 'utils/validators/recommendation-cards/nutrition-plan.validator';

import { NutritionPlanToolbar } from './NutritionPlanToolbar';
import { RichTextObjectArrayInput } from './RichTextObjectArrayInput';

export const NutritionPlanEdit: FC<EditProps> = (props) => {
  const notify = useNotify();

  return (
    <Edit
      title="Nutrition Plan Edit"
      onSuccess={() => {
        notify('Nutrition Plan updated', 'success');
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      mutationMode={'pessimistic'}
      {...props}>
      <TabbedForm toolbar={<NutritionPlanToolbar />} validate={nutritionPlanValidator}>
        <FormTab label="Content">
          <TextInput source="title" sortable={false} fullWidth />
          <TextInput source="description" sortable={false} fullWidth multiline />
          <TextInput source="additionalInfo" sortable={false} fullWidth multiline />
          <ArrayInput source="goals">
            <SimpleFormIterator>
              <TextInput source="" fullWidth multiline label="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Include Products">
          <TextField source="title" sortable={false} />
          <RichTextObjectArrayInput source={'includeProducts'} />
        </FormTab>
        <FormTab label="Exclude Products">
          <TextField source="title" sortable={false} />
          <RichTextObjectArrayInput source={'excludeProducts'} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
