import { PromocodePeriodEnum } from 'api/generated';
import * as React from 'react';
import { Button, useMutation, useNotify, useRefresh } from 'react-admin';
import { ResourceName } from 'types/resources';

type GeneratePromocodesButtonProps = {
  count: number | undefined;
  period: PromocodePeriodEnum;
};

export const GeneratePromocodesButton: React.FC<GeneratePromocodesButtonProps> = ({ count, period }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [approve, mutationData] = useMutation(
    {
      type: 'createMany',
      resource: ResourceName.PROMOCODE,
      payload: { count, period },
    },
    {
      onSuccess: () => refresh(),
      onFailure: (error) => notify(error.response.data.message, 'error'),
    },
  );
  return <Button label="Generate" onClick={approve} disabled={!count || !period || mutationData.loading} />;
};
