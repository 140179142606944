import {
  ThirdTypeCard,
  ThirdTypeCardDysfunctionTypeEnum,
  ThirdTypeCardsBiomarkerOutsideNormZoneEnum,
} from 'api/generated';
import { ContentInput } from 'components';
import { FC } from 'react';
import {
  ArrayInput,
  Edit,
  EditProps,
  FormDataConsumer,
  FormTab,
  maxValue,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';
import { biomarkersCardsValidator, stringFieldsCardValidator } from 'utils/validators/recommendation-cards';

const outsideNormZoneChoices = Object.values(ThirdTypeCardsBiomarkerOutsideNormZoneEnum).map(
  (outsideNormZoneValue) => ({
    id: outsideNormZoneValue,
    name: outsideNormZoneValue,
  }),
);

import styles from './styles.module.scss';
import { ThirdTypeCardToolbar } from './ThirdTypeCardToolbar';
import { TotalScore } from './TotalScore';

export const ThirdTypeCardEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Edit
      title="Recommendation Edit"
      onSuccess={() => {
        notify('Card updated', 'success');
        redirect(`/${ResourceName.THIRD_TYPE_CARDS}/${props.id}/show`);
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      mutationMode={'pessimistic'}
      {...props}>
      <TabbedForm toolbar={<ThirdTypeCardToolbar />} validate={stringFieldsCardValidator}>
        <FormTab label="summary">
          <TextInput source="title" fullWidth />
          <TextInput source="description" fullWidth />
          <SelectInput
            label="Dysfunction type"
            source="dysfunctionType"
            choices={[
              { id: ThirdTypeCardDysfunctionTypeEnum.Dysfunction, name: ThirdTypeCardDysfunctionTypeEnum.Dysfunction },
              {
                id: ThirdTypeCardDysfunctionTypeEnum.NutrientStatus,
                name: ThirdTypeCardDysfunctionTypeEnum.NutrientStatus,
              },
            ]}
          />
        </FormTab>
        <FormTab label="Content">
          <TextField source="title" sortable={false} />
          <ContentInput source="content" />
        </FormTab>
        <FormTab label="Biomarkers">
          <TextField source="title" sortable={false} />
          <ArrayInput source="ttcBiomarkers" label="Biomarkers" validate={biomarkersCardsValidator}>
            <SimpleFormIterator className={styles['form-iterator']}>
              <ReferenceInput
                label="Biomarker Name"
                source="biomarkerId"
                reference={ResourceName.BIOMARKERS}
                perPage={10000}>
                <SelectInput optionText="name" source="biomarkerId" />
              </ReferenceInput>
              <SelectInput label="Outside Norm Zone" source="outsideNormZone" choices={outsideNormZoneChoices} />
              <NumberInput source="maleNormField" label="Male Norm Field" min={0} />
              <NumberInput source="femaleNormField" label="Female Norm Field" min={0} />

              <FormDataConsumer>
                {({ formData, getSource }) => {
                  const biomarkersScore = (formData as ThirdTypeCard).ttcBiomarkers?.reduce(
                    (acc, biomarker) => acc + (biomarker?.score || 0),
                    0,
                  );
                  const totalScore = formData?.ratios
                    ? formData?.ratios?.reduce(
                        (acc: any, ratio: any) => (acc || 0) + (ratio?.score || 0),
                        biomarkersScore,
                      )
                    : biomarkersScore;

                  return (
                    getSource && (
                      <>
                        <NumberInput
                          source={getSource('score')}
                          label="Score"
                          min={0}
                          max={10}
                          validate={maxValue(10)}
                          defaultValue={null}
                          fullWidth
                        />
                        <TotalScore totalScore={totalScore as number} />
                      </>
                    )
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Ratios">
          <ArrayInput source="ratios">
            <SimpleFormIterator className={styles['form-iterator']}>
              <ReferenceInput
                label="Biomarker Dividend Name"
                source="dividendBiomarkerId"
                reference={ResourceName.BIOMARKERS}
                perPage={10000}>
                <SelectInput optionText="name" source="dividendBiomarkerId" />
              </ReferenceInput>
              <ReferenceInput
                label="Biomarker Divider Name"
                source="dividerBiomarkerId"
                reference={ResourceName.BIOMARKERS}
                perPage={10000}>
                <SelectInput optionText="name" source="dividerBiomarkerId" />
              </ReferenceInput>
              <NumberInput source="maleNormField" label="Male Norm Field" min={0} />
              <NumberInput source="femaleNormField" label="Female Norm Field" min={0} />
              <SelectInput label="Outside Norm Zone" source="outsideNormZone" choices={outsideNormZoneChoices} />
              <FormDataConsumer>
                {({ formData, getSource }) => {
                  const biomarkersScore = (formData as ThirdTypeCard).ttcBiomarkers?.reduce(
                    (acc, biomarker) => acc + (biomarker?.score || 0),
                    0,
                  );
                  const totalScore = formData?.ratios
                    ? formData?.ratios?.reduce(
                        (acc: any, ratio: any) => (acc || 0) + (ratio?.score || 0),
                        biomarkersScore,
                      )
                    : biomarkersScore;

                  return (
                    getSource && (
                      <>
                        <NumberInput
                          source={getSource('score')}
                          label="Score"
                          min={0}
                          max={10}
                          validate={maxValue(10)}
                          defaultValue={null}
                        />
                        <TotalScore totalScore={totalScore as number} />
                      </>
                    )
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
