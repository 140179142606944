import { ContentInput } from 'components/ContentInput/ContentInput';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  ImageField,
  ImageInput,
  minLength,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';

export const CheckupsCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();

  return (
    <Create onFailure={(err) => notify(getErrorMessage(err), 'error')} {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required(), minLength(4)]} spellCheck={false} />
        <ImageInput source="avatar" validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ContentInput source={'description'} />
        <ReferenceArrayInput
          label="Biomarkers"
          source={ResourceName.BIOMARKERS}
          reference={ResourceName.BIOMARKERS}
          perPage={10000}
          helperText="Choose list of biomarkers assigned to checkup">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
