import { IncludeProducts } from 'api/generated/api';
import { useFieldsStyles } from 'assets/styles/common';
import React, { FC } from 'react';
import {
  DateField,
  FunctionField,
  NumberField,
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { productsMapping } from 'types/common';

const RichTextObjectArrayField = ({ record, source }: any) => {
  const keys = Object.keys(record[source]) as Array<keyof IncludeProducts>;

  return (
    <ul>
      {keys.map((key) => {
        return (
          <>
            {record[source][key] ? (
              <li key={key}>
                <h3>{productsMapping[key]}</h3>
                <RichTextField source={`${source}.${key}`} />
              </li>
            ) : (
              ''
            )}
          </>
        );
      })}
    </ul>
  );
};

export const NutritionPlanShow: FC<ShowProps> = (props) => {
  const { textField, numberField, richTextField } = useFieldsStyles();
  return (
    <Show title="Nutrition plan view" {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="title" sortable={false} />
          <NumberField source="id" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />
        </Tab>
        <Tab label="Content">
          <TextField source="title" sortable={false} />
          <RichTextField source="description" sortable={false} />
          <RichTextField source="additionalInfo" sortable={false} />
          <FunctionField
            source="goals"
            render={(record: any) => {
              return (
                <ul>
                  {record.goals.map((_: any, id: number) => (
                    <li key={id}>
                      <TextField source={`goals[${id}]`} />
                    </li>
                  ))}
                </ul>
              );
            }}
          />
        </Tab>
        <Tab label="Include Products">
          <TextField source="title" sortable={false} />
          <RichTextObjectArrayField source="includeProducts" />
        </Tab>
        <Tab label="Exclude Products">
          <TextField source="title" sortable={false} />
          <RichTextObjectArrayField source="excludeProducts" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
