import { SecondTypeCardsBiomarkerOutsideNormZoneEnum } from 'api/generated';
import { ContentInput } from 'components';
import { FC } from 'react';
import {
  ArrayInput,
  Edit,
  EditProps,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin';
import { getErrorMessage } from 'utils/errors-messages';
import { biomarkersCardsValidator, stringFieldsCardValidator } from 'utils/validators/recommendation-cards';

import { SecondTypeCardToolbar } from './SecondTypeCardToolbar';
import styles from './styles.module.scss';

export const SecondTypeCardEdit: FC<EditProps> = (props) => {
  const notify = useNotify();

  const outsideNormZoneChoices = Object.values(SecondTypeCardsBiomarkerOutsideNormZoneEnum).map(
    (outsideNormZoneValue) => ({
      id: outsideNormZoneValue,
      name: outsideNormZoneValue,
    }),
  );

  return (
    <Edit
      title="Recommendation Edit"
      onSuccess={() => {
        notify('Card updated', 'success');
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      mutationMode={'pessimistic'}
      {...props}>
      <TabbedForm toolbar={<SecondTypeCardToolbar />} validate={stringFieldsCardValidator}>
        <FormTab label="summary">
          <TextInput source="title" fullWidth />
          <TextInput source="description" fullWidth />
        </FormTab>
        <FormTab label="Content">
          <TextField source="title" />
          <ContentInput source="content" />
        </FormTab>
        <FormTab label="Biomarkers">
          <TextField source="title" />
          <ArrayInput source="stcBiomarkers" label="Biomarkers" validate={biomarkersCardsValidator}>
            <SimpleFormIterator className={styles['form-iterator']}>
              <ReferenceInput label="Biomarker Name" source="biomarkerId" reference="admins/biomarkers" perPage={10000}>
                <SelectInput optionText="name" source="biomarkerId" />
              </ReferenceInput>
              <SelectInput label="Outside Norm Zone" source="outsideNormZone" choices={outsideNormZoneChoices} />
              <NumberInput source="maleNormField" label="Male Norm Field" min={0} />
              <NumberInput source="femaleNormField" label="Female Norm Field" min={0} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
