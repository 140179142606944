import Decimal from 'decimal.js';
import { FC } from 'react';

import styles from './styles.module.scss';

export const TotalScore: FC<{ totalScore: number }> = ({ totalScore }) => {
  const score = totalScore && String(new Decimal(totalScore).toDecimalPlaces(2));

  const isError = totalScore > 10;

  return <p className={isError ? styles['total-score_error'] : undefined}>{`Total score = ${score}`}</p>;
};
