import { createTheme } from '@material-ui/core/styles';
import {
  Chat,
  CompareArrows,
  FavoriteRounded,
  FeaturedPlayList,
  FlipSharp,
  MonetizationOn,
  People,
  PlaylistAddCheck,
  Restaurant,
} from '@material-ui/icons';
import nutritionPlans from 'entities/nutrition-plans';
import units from 'entities/units';
import authProvider from 'providers/auth-provider';
import dataProvider from 'providers/data-provider';
import { Admin, Resource } from 'react-admin';
import { ResourceName } from 'types/resources';

import {
  analysisKinds,
  biomarkers,
  chats,
  checkups,
  nutritionists,
  promocodes,
  secondTypeCards,
  thirdTypeCards,
} from './entities';
import { CustomLayout } from 'components';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#646EFA',
      contrastText: '#FFFFFF',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: { default: '#F6F6F6', paper: '#F9F9F9' },
    text: {
      primary: '#262626',
      secondary: '#8E8E8E',
    },
  },
});

const App = (): JSX.Element => {
  return (
    <Admin
      authProvider={authProvider}
      theme={theme}
      dataProvider={dataProvider()}
      title="Biogeek Panel"
      layout={CustomLayout}>
      <Resource name={ResourceName.EXPERTS} options={{ label: 'Experts' }} icon={People} {...nutritionists} />
      <Resource name={ResourceName.CHECKUPS} options={{ label: 'Checkups' }} icon={PlaylistAddCheck} {...checkups} />
      <Resource
        name={ResourceName.SECOND_TYPE_CARDS}
        options={{ label: '2nd Type Cards' }}
        icon={FeaturedPlayList}
        {...secondTypeCards}
      />
      <Resource
        name={ResourceName.THIRD_TYPE_CARDS}
        options={{ label: '3rd Type Cards' }}
        icon={FeaturedPlayList}
        {...thirdTypeCards}
      />
      <Resource name="biomarkers" />
      <Resource
        name={ResourceName.BIOMARKERS}
        options={{ label: 'Biomarkers' }}
        icon={FavoriteRounded}
        {...biomarkers}
      />
      <Resource
        name={ResourceName.NUTRITION_PLANS}
        options={{ label: 'Nutrition Plans' }}
        icon={Restaurant}
        {...nutritionPlans}
      />
      <Resource
        name={ResourceName.ANALYSIS_KINDS}
        options={{ label: 'Analysis Kinds' }}
        icon={FlipSharp}
        {...analysisKinds}
      />

      <Resource name={ResourceName.UNITS} options={{ label: 'Units' }} icon={CompareArrows} {...units} />
      <Resource name={ResourceName.PROMOCODE} options={{ label: 'Promocodes' }} icon={MonetizationOn} {...promocodes} />
      <Resource name={ResourceName.CHATS} options={{ label: 'Chats' }} icon={Chat} {...chats} />
    </Admin>
  );
};

export default App;
