import { useEffect, useState } from 'react';
import { AppBar, AppBarProps, useRefresh, Button } from 'react-admin';
import { getLanguageFromStorage, setLanguageToStorage } from 'services/storageService';
import { LanguagesEnum } from 'types/common';

export const CustomAppBar = (props: AppBarProps): JSX.Element => {
  const refresh = useRefresh();

  const [languageState, setLanguage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const language = getLanguageFromStorage();
    if (language) {
      setLanguage(language);
    } else {
      setLanguage(LanguagesEnum.ru);
      setLanguageToStorage(LanguagesEnum.ru);
    }
  }, []);

  const handleChangeLang = () => {
    if (languageState === 'ru') {
      setLanguageToStorage(LanguagesEnum.en);
      setLanguage(LanguagesEnum.en);
    }
    if (languageState === 'en') {
      setLanguageToStorage(LanguagesEnum.ru);
      setLanguage(LanguagesEnum.ru);
    }
    refresh();
  };

  return (
    <AppBar {...props}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <p>Current language: {languageState}</p>
        <Button
          onClick={handleChangeLang}
          label="Change Language"
          size="small"
          variant="contained"
          style={{ height: 'fit-content', marginLeft: '40px', marginRight: '40px' }}
        />
      </div>
    </AppBar>
  );
};
