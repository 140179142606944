import React, { FC } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  ImageField,
  NumberField,
  ReferenceField,
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { ResourceName } from 'types/resources';

export const CheckupsShow: FC<ShowProps> = (props) => {
  return (
    <Show title="Checkups view" {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="name" sortable={false} />
          <NumberField source="id" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <ImageField source="avatar" sortable={false} />
        </Tab>
        <Tab label="Description">
          <TextField source="name" sortable={false} />
          <RichTextField source="description" sortable={false} />
        </Tab>
        <Tab label="Biomarkers">
          <TextField source="name" sortable={false} />
          <ArrayField source="biomarkers" fullWidth>
            <Datagrid>
              <ReferenceField label="Name" source="id" reference={ResourceName.BIOMARKERS}>
                <TextField source="id" />
              </ReferenceField>
              <TextField source="label" fullWidth />
              <TextField source="name" fullWidth />
              <TextField source="unit" fullWidth />
              <TextField source="analysisKinds" fullWidth />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
