import {
  SecondTypeCardsBiomarker,
  ThirdTypeCardBiomarkerDto,
  ThirdTypeCardBiomarkerDtoOutsideNormZoneEnum,
} from 'api/generated';

export const biomarkersCardsValidator = (
  record: Array<ThirdTypeCardBiomarkerDto | SecondTypeCardsBiomarker>,
): string | undefined => {
  const hasEmptyFields = record.some((biomarker) => {
    return !biomarker || Object.values(biomarker).some((i) => !i);
  });

  if (hasEmptyFields) {
    return 'Fill all fields';
  }

  const biomarkerIds = record.reduce((acc: string[], current) => [...acc, current.biomarkerId], []);
  const uniqueBiomarkersIds = [...Array.from(new Set(biomarkerIds))];

  if (biomarkerIds.length !== uniqueBiomarkersIds.length) {
    return 'Biomarkers records have to be unique';
  }

  if (!record.find((biomarker) => biomarker.outsideNormZone === ThirdTypeCardBiomarkerDtoOutsideNormZoneEnum.Greater)) {
    return 'At least one biomarker should be outside the normal range';
  }

  if (Array.isArray(record)) {
    const score = (record as Array<ThirdTypeCardBiomarkerDto>)?.reduce((acc, biomarker) => acc + biomarker.score, 0);

    if (score > 10) {
      return 'Total score must be less than 10';
    }
  }
};
