import { FC } from 'react';
import {
  ChipField,
  DateField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  RichTextField,
  Show,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { ResourceName } from 'types/resources';

import { useTabStyles } from './styles';

export const BiomarkerShow: FC<ShowProps> = (props) => {
  const { tab } = useTabStyles();

  return (
    <Show title="Biomarker" {...props}>
      <TabbedShowLayout>
        <Tab label="summary" contentClassName={tab}>
          <NumberField source="id" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />
          <TextField source="label" sortable={false} />
          <TextField source="name" sortable={false} />
          <ReferenceField source="unitId" reference={ResourceName.UNITS}>
            <ChipField source="name" />
          </ReferenceField>
          <NumberField source="lowerNormMale" sortable={false} />
          <NumberField source="upperNormMale" sortable={false} />
          <NumberField source="lowerNormFemale" sortable={false} />
          <NumberField source="upperNormFemale" sortable={false} />
          <NumberField source="lowerNormConventional" sortable={false} />
          <NumberField source="upperNormConventional" sortable={false} />
          <NumberField source="lowerNormInvalidValue" sortable={false} />
          <NumberField source="upperNormInvalidValue" sortable={false} />
          <NumberField source="numberMoles" sortable={false} />
          <ReferenceArrayField label="Analysis Kinds" reference={ResourceName.ANALYSIS_KINDS} source="analysisKindsIds">
            <SingleFieldList>
              <ChipField source="label" />
            </SingleFieldList>
          </ReferenceArrayField>
          <RichTextField source="description" label="Description" />
        </Tab>
        <Tab label="Greater Content">
          <TextField source="label" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="firstTypeCard.greaterLabel" label="Greater Label" />
          <TextField source="firstTypeCard.greaterDescription" label="Greater Description" />
          <RichTextField source="firstTypeCard.greaterContent" label="Greater Content" />
        </Tab>
        <Tab label="Lower Content">
          <TextField source="label" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="firstTypeCard.lowerLabel" label="Lower Label" />
          <TextField source="firstTypeCard.lowerDescription" label="Lower Description" />
          <RichTextField source="firstTypeCard.lowerContent" label="Lower Content" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
