import { NutritionistEdit } from './NutritionistsEdit';
import { NutritionistsList } from './NutritionistsList';
import { NutritionistShow } from './NutritionistsShow';
export { NutritionistsToolbar } from './NutritionistsToolbar';

export default {
  list: NutritionistsList,
  edit: NutritionistEdit,
  show: NutritionistShow,
};
