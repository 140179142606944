import { ThirdTypeCardsBiomarkerOutsideNormZoneEnum } from 'api/generated';
import { useFieldsStyles } from 'assets/styles/common';
import React, { FC } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { outsideNormZoneSymbolMapping } from 'types/common';
import { ResourceName } from 'types/resources';

export const ThirdTypeCardShow: FC<ShowProps> = (props) => {
  const { textField, numberField } = useFieldsStyles();
  return (
    <Show title="Recommendation view" {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="title" sortable={false} />
          <TextField source="description" sortable={false} />
          <TextField source="dysfunctionType" sortable={false} />
          <TextField source="id" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />
        </Tab>
        <Tab label="Content">
          <TextField source="title" sortable={false} />
          <RichTextField source="content" sortable={false} />
        </Tab>
        <Tab label="Biomarkers">
          <TextField source="title" sortable={false} />
          <ArrayField source="ttcBiomarkers" label="Biomarkers">
            <Datagrid rowClick={'show'}>
              <ReferenceField label="Name" source="biomarkerId" reference={ResourceName.BIOMARKERS}>
                <TextField source="name" />
              </ReferenceField>

              <ReferenceField label="Label" source="biomarkerId" reference={ResourceName.BIOMARKERS}>
                <TextField source="label" />
              </ReferenceField>
              <NumberField source="maleNormField" className={numberField} />
              <NumberField source="femaleNormField" className={numberField} />
              <NumberField source="score" className={numberField} />
              <FunctionField
                className={textField}
                source="outsideNormZone"
                render={(record: any) => {
                  return outsideNormZoneSymbolMapping[
                    record.outsideNormZone as ThirdTypeCardsBiomarkerOutsideNormZoneEnum
                  ];
                }}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Ratios">
          <ArrayField source="ratios">
            <Datagrid rowClick="show">
              <ReferenceField label="Dividend" source="dividendBiomarkerId" reference={ResourceName.BIOMARKERS}>
                <TextField source="name" />
              </ReferenceField>

              <ReferenceField label="Divider" source="dividerBiomarkerId" reference={ResourceName.BIOMARKERS}>
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="maleNormField" className={numberField} />
              <NumberField source="femaleNormField" className={numberField} />
              <NumberField source="score" className={numberField} />
              <FunctionField
                className={textField}
                source="outsideNormZone"
                render={(record: any) => {
                  return outsideNormZoneSymbolMapping[
                    record.outsideNormZone as ThirdTypeCardsBiomarkerOutsideNormZoneEnum
                  ];
                }}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
