import { http } from 'services/http';

import {
  AdminsAnalysisKindsApi,
  AdminsApi,
  AdminsBiomarkersApi,
  AdminsChatsApi,
  AdminsCheckupsApi,
  AdminsExpertsApi,
  AdminsNutritionPlansApi,
  AdminsPromocodesApi,
  AdminsSecondTypeCardsApi,
  AdminsThirdTypeCardsApi,
  AdminsUnitsApi,
} from './generated';

export const api = {
  AdminsApi: new AdminsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsNutritionPlansApi: new AdminsNutritionPlansApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsSecondTypeCardsApi: new AdminsSecondTypeCardsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsThirdTypeCardsApi: new AdminsThirdTypeCardsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsAnalysisKindsApi: new AdminsAnalysisKindsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsUnitsApi: new AdminsUnitsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsPromocodesApi: new AdminsPromocodesApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsChatsApi: new AdminsChatsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsExpertsApi: new AdminsExpertsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsCheckupsApi: new AdminsCheckupsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AdminsBiomarkersApi: new AdminsBiomarkersApi(undefined, process.env.REACT_APP_API_URL, http.axios),
};
