import { useFieldsStyles } from 'assets/styles/common';
import { ShowRecordButton } from 'components';
import { FC } from 'react';
import { Datagrid, DateField, List, ListProps, TextField } from 'react-admin';

import { ChatsFilters } from './ChatsFilters';

export const ChatsList: FC<ListProps> = (props) => {
  const { textField } = useFieldsStyles();

  return (
    <List {...props} filters={<ChatsFilters />} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" className={textField} label="id" sortable={false} />
        <TextField source="patient.name" className={textField} label="Patient name" sortable={false} />
        <TextField source="patient.email" className={textField} label="Patient email" sortable={false} />
        <TextField source="expert.name" className={textField} label="Expert name" sortable={false} />
        <TextField source="expert.email" className={textField} label="Expert email" sortable={false} />
        <DateField source="createdAt" label="Created At" sortable={false} />
        <DateField source="updatedAt" label="Updated At" sortable={false} />
        <ShowRecordButton />
      </Datagrid>
    </List>
  );
};
