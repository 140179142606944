import { ChatsGetMessageResDto } from 'api/generated';
import { FC, useEffect, useState } from 'react';
import { Show, ShowProps, SimpleShowLayout } from 'react-admin';

import { api } from './../../api';
import styles from './styles.module.scss';

export const ChatsShow: FC<ShowProps> = (props) => {
  const [data, setData] = useState<ChatsGetMessageResDto[]>();

  const getChatById = async () => {
    const { data } = await api.AdminsChatsApi.adminChatsControllerFindChatMessages(props.id as string);
    return data;
  };

  useEffect(() => {
    getChatById()
      .then(({ data }) => setData(data))
      .catch((e) => console.warn(e));
  }, []);

  const isEmpty = data?.length === 0;

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <div>
          {isEmpty ? (
            <>Сообщений нет.</>
          ) : (
            <>
              {data?.map((message) => (
                <div key={message.id} className={styles.message}>
                  <div className={styles.header}>
                    <p className={styles.name}>{message.sender.name}</p>{' '}
                    <p className={styles.date}>
                      {new Date(message.createdAt).toLocaleDateString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </p>
                  </div>
                  <p className={styles.text}>{message.text}</p>
                  {message.attachments &&
                    message.attachments.map((attachment, index) => (
                      <a href={`${attachment.URL}`} key={index}>
                        {attachment.filename}
                      </a>
                    ))}
                </div>
              ))}
            </>
          )}
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
