import { FC } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  CreateProps,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';

import { CustomUnitSelectInput } from './components';
import styles from './styles.module.scss';
import { unitCoefficientsValidator, unitValidator } from './unit.validator';
import { UnitsToolbar } from './UnitsToolbar';

export const UnitCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <Create
      onSuccess={(props) => {
        notify('Unit created', 'success');
        redirect(`/${ResourceName.UNITS}/${props.data.id}/show`);
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      {...props}>
      <SimpleForm toolbar={<UnitsToolbar />} validate={unitValidator}>
        <TextInput source="name" label="Name" fullWidth helperText="Input name for unit" />
        <ArrayInput
          source="unitCoefficients"
          label="Coefficients"
          helperText="Add coefficients to another units if necessary"
          validate={unitCoefficientsValidator}>
          <SimpleFormIterator className={styles['form-iterator']}>
            <ReferenceInput
              fullWidth
              allowEmpty
              label="Unit"
              source="compareUnitId"
              reference={ResourceName.UNITS}
              perPage={10000}
              helperText="Choose unit to compare"
              validate={required('Field is required')}>
              <CustomUnitSelectInput />
            </ReferenceInput>
            <NumberInput
              fullWidth
              source="coefficient"
              label="Coefficient"
              placeholder="0.01"
              helperText="Input coefficient"
              validate={required('Field is required')}
            />
            <BooleanInput source="useNumberMoles" defaultValue={false} label="Use number moles" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
