import {
  ThirdTypeCard,
  ThirdTypeCardDysfunctionTypeEnum,
  ThirdTypeCardsBiomarkerOutsideNormZoneEnum,
} from 'api/generated';
import { ContentInput } from 'components';
import { FC } from 'react';
import {
  ArrayInput,
  Create,
  CreateProps,
  FormDataConsumer,
  FormTab,
  maxValue,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';
import { biomarkersCardsValidator, stringFieldsCardValidator } from 'utils/validators/recommendation-cards';

import { biomarkersThirdTypeCardValidator } from '../../utils/validators/recommendation-cards/biomarkers-third-type-card.validator';
import styles from './styles.module.scss';
import { ThirdTypeCardToolbar } from './ThirdTypeCardToolbar';
import { TotalScore } from './TotalScore';

const outsideNormZoneChoices = Object.values(ThirdTypeCardsBiomarkerOutsideNormZoneEnum).map(
  (outsideNormZoneValue) => ({
    id: outsideNormZoneValue,
    name: outsideNormZoneValue,
  }),
);

const dysfunctionTypeChoices = Object.values(ThirdTypeCardDysfunctionTypeEnum).map((value) => ({
  id: value,
  name: value,
}));

export const ThirdTypeCardCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      {...props}
      onSuccess={(props) => {
        notify('Third type card created', 'success');
        redirect(`/${ResourceName.THIRD_TYPE_CARDS}/${props.data.id}/show`);
      }}
      transform={(data) => {
        return data;
      }}>
      <TabbedForm toolbar={<ThirdTypeCardToolbar />} validate={stringFieldsCardValidator}>
        <FormTab label="summary">
          <TextInput source="name" label="Name" fullWidth helperText="Input name for recommendation" />
          <TextInput source="title" label="Title" fullWidth helperText="Input label for recommendation" />
          <TextInput source="description" fullWidth helperText="Add short description" />
          <SelectInput
            source="dysfunctionType"
            choices={dysfunctionTypeChoices}
            defaultValue={dysfunctionTypeChoices[0].name}
            helperText="Select a dysfunction type for third type card"
          />
        </FormTab>
        <FormTab label="Content">
          <ContentInput source="content" />
        </FormTab>
        <FormTab label="Biomarkers">
          <ArrayInput
            source="ttcBiomarkers"
            label="Biomarkers"
            initialValue={[
              {
                biomarkerId: null,
                outsideNormZone: null,
                maleNormField: null,
                femaleNormField: null,
              },
            ]}
            validate={[biomarkersCardsValidator, biomarkersThirdTypeCardValidator]}>
            <SimpleFormIterator className={styles['form-iterator']}>
              <ReferenceInput
                label="Biomarker Name"
                source="biomarkerId"
                reference={ResourceName.BIOMARKERS}
                perPage={10000}>
                <SelectInput optionText="name" source="biomarkerId" defaultValue={null} />
              </ReferenceInput>
              <SelectInput
                label="Outside Norm Zone"
                source="outsideNormZone"
                choices={outsideNormZoneChoices}
                defaultValue={null}
              />
              <NumberInput source="maleNormField" label="Male Norm Field" min={0} defaultValue={null} />
              <NumberInput source="femaleNormField" label="Female Norm Field" min={0} defaultValue={null} />
              <FormDataConsumer>
                {({ formData, getSource }) => {
                  const biomarkersScore = (formData as ThirdTypeCard).ttcBiomarkers?.reduce(
                    (acc, biomarker) => acc + (biomarker?.score || 0),
                    0,
                  );
                  const totalScore = formData?.ratios
                    ? formData?.ratios?.reduce(
                        (acc: any, ratio: any) => (acc || 0) + (ratio?.score || 0),
                        biomarkersScore,
                      )
                    : biomarkersScore;

                  return (
                    getSource && (
                      <>
                        <NumberInput
                          source={getSource('score')}
                          label="Score"
                          min={0}
                          max={10}
                          validate={maxValue(10)}
                          defaultValue={null}
                          fullWidth
                        />
                        <TotalScore totalScore={totalScore as number} />
                      </>
                    )
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Ratios">
          <ArrayInput
            source="ratios"
            initialValue={[
              {
                dividerBiomarkerId: null,
                dividendBiomarkerId: null,
                maleNormField: null,
                femaleNormField: null,
              },
            ]}>
            <SimpleFormIterator className={styles['form-iterator']}>
              <ReferenceInput
                label="Biomarker Dividend Name"
                source="dividendBiomarkerId"
                reference={ResourceName.BIOMARKERS}
                perPage={10000}>
                <SelectInput optionText="name" source="dividendBiomarkerId" />
              </ReferenceInput>
              <ReferenceInput
                label="Biomarker Divider Name"
                source="dividerBiomarkerId"
                reference={ResourceName.BIOMARKERS}
                perPage={10000}>
                <SelectInput optionText="name" source="dividerBiomarkerId" />
              </ReferenceInput>
              <NumberInput source="maleNormField" label="Male Norm Field" min={0} />
              <NumberInput source="femaleNormField" label="Female Norm Field" min={0} />
              <SelectInput label="Outside Norm Zone" source="outsideNormZone" choices={outsideNormZoneChoices} />
              <FormDataConsumer>
                {({ formData, getSource }) => {
                  const biomarkersScore = (formData as ThirdTypeCard).ttcBiomarkers?.reduce(
                    (acc, biomarker) => acc + (biomarker?.score || 0),
                    0,
                  );
                  const totalScore = formData?.ratios
                    ? formData?.ratios?.reduce(
                        (acc: any, ratio: any) => (acc || 0) + (ratio?.score || 0),
                        biomarkersScore,
                      )
                    : biomarkersScore;

                  return (
                    getSource && (
                      <>
                        <NumberInput
                          source={getSource('score')}
                          label="Score"
                          min={0}
                          max={10}
                          validate={maxValue(10)}
                          defaultValue={null}
                        />
                        <TotalScore totalScore={totalScore as number} />
                      </>
                    )
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
