import { IncludeProducts } from 'api/generated';
import { TextInput } from 'react-admin';
import { productsMapping } from 'types/common';

export const RichTextObjectArrayInput = ({ record, source }: any) => {
  const keys = Object.keys(productsMapping) as Array<keyof IncludeProducts>;

  return (
    <ul>
      {keys.map((key) => {
        return (
          <li key={key}>
            <h3>{productsMapping[key]}</h3>
            <TextInput source={`${source}.${key}`} label="" multiline fullWidth initialValue="" />
          </li>
        );
      })}
    </ul>
  );
};
