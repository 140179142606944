import { makeStyles } from '@material-ui/core';

export const useFieldsStyles = makeStyles({
  textField: {
    display: 'block',
    width: '200px',
  },
  richTextField: {
    display: 'block',
    width: '400px',
  },
  numberField: {
    display: 'block',
    width: '100px',
  },
  imageField: {
    '& img': {
      maxHeight: '4rem',
    },
  },
  nameField: {
    display: 'block',
    width: '300px',
    maxWidth: '300px',
    wordBreak: 'break-word',
  },
});
