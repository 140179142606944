import React, { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { ResourceName } from 'types/resources';

export const UnitShow: FC<ShowProps> = (props) => {
  return (
    <Show title="Unit view" {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" sortable={false} />
          <TextField source="name" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />
        </Tab>
        <Tab label="Biomarkers">
          <TextField source="name" sortable={false} />
          <ArrayField source="biomarkers" label="Biomarkers">
            <Datagrid>
              <ReferenceField label="Name" source="id" reference={ResourceName.BIOMARKERS}>
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField label="Label" source="id" reference={ResourceName.BIOMARKERS}>
                <TextField source="label" />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Coefficients">
          <TextField source="name" />
          <ArrayField source="unitCoefficients" label="Coefficients">
            <Datagrid>
              <ReferenceField label="Source Unit" source="sourceUnitId" reference={ResourceName.UNITS}>
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField label="Compare Unit" source="compareUnitId" reference={ResourceName.UNITS}>
                <TextField source="name" />
              </ReferenceField>
              <BooleanField source="useNumberMoles" label="Use number moles" />

              <TextField source="coefficient" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
