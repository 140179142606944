import { NutritionPlanCreate } from './NutritionPlanCreate';
import { NutritionPlanEdit } from './NutritionPlanEdit';
import { NutritionPlanShow } from './NutritionPlanShow';
import { NutritionPlansList } from './NutritionPlansList';

export default {
  list: NutritionPlansList,
  edit: NutritionPlanEdit,
  show: NutritionPlanShow,
  create: NutritionPlanCreate,
};
