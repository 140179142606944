import { api } from 'api';
import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AuthActionType } from 'react-admin';
import { http } from 'services/http';

export default (type: AuthActionType, params: any): Promise<void> => {
  if (type === AUTH_LOGIN) {
    const { username: email, password } = params;

    return api.AdminsApi.adminsControllerSignIn({ email, password })
      .then(({ data: { accessToken } }) => {
        http.setAuthorizationHeader(accessToken);
        localStorage.setItem('token', accessToken);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const {
      response: { status },
    } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const token = localStorage.getItem('token');
    if (token) {
      http.setAuthorizationHeader(token);
      return Promise.resolve();
    } else return Promise.reject();
  }
  return Promise.reject('Unknown method');
};
