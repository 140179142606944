import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import React, { FC, ReactElement } from 'react';
import { Filter, ResourceComponentProps, SelectInput, TextInput } from 'react-admin';
import { ResourceName } from 'types/resources';

export const NutritionistsFilter: FC<ResourceComponentProps> = (props): ReactElement => (
  <Filter {...props}>
    <TextInput key="name" source={`${ResourceName.EXPERTS}.filter`} label="Search by name/email" alwaysOn />
    <SelectInput
      key="documentStatus"
      source={`${ResourceName.EXPERTS}.documentStatus`}
      label="Document Status"
      alwaysOn
      choices={[
        { id: ExpertEducationDocumentStatusEnum.NotSent, name: 'Not Sent' },
        { id: ExpertEducationDocumentStatusEnum.Checking, name: 'Checking' },
        { id: ExpertEducationDocumentStatusEnum.Approved, name: 'Approved' },
        { id: ExpertEducationDocumentStatusEnum.Declined, name: 'Declined' },
      ]}
    />
  </Filter>
);
