import RichTextInput from 'ra-input-rich-text';
import React, { FC } from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  EditProps,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';
import { biomarkerValidator } from 'utils/validators/recommendation-cards/biomarker.validator';

import { BiomarkerToolbar } from './BiomarkerToolbar';
import { useTabStyles } from './styles';

export const BiomarkerEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  const { formTab } = useTabStyles();

  return (
    <Edit
      title="Biomarker Edit"
      onSuccess={() => {
        notify('Biomarker updated', 'success');
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      mutationMode={'pessimistic'}
      {...props}>
      <TabbedForm toolbar={<BiomarkerToolbar />} validate={biomarkerValidator}>
        <FormTab label="summary" contentClassName={formTab}>
          <TextInput source="label" sortable={false} fullWidth />
          <TextInput source="name" sortable={false} fullWidth />

          <NumberInput source="lowerNormMale" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormMale" sortable={false} min={0} fullWidth />
          <NumberInput source="lowerNormFemale" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormFemale" sortable={false} min={0} fullWidth />
          <NumberInput source="lowerNormConventional" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormConventional" sortable={false} min={0} fullWidth />
          <NumberInput source="lowerNormInvalidValue" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormInvalidValue" sortable={false} min={0} fullWidth />

          <ReferenceInput
            fullWidth
            allowEmpty
            label="Unit"
            source="unitId"
            reference={ResourceName.UNITS}
            perPage={10000}>
            <SelectInput source="unitId" helperText="Assign unit to a biomarker" />
          </ReferenceInput>
          <ReferenceArrayInput
            label="Analysis Kinds"
            source="analysisKindsIds"
            reference={ResourceName.ANALYSIS_KINDS}
            perPage={10000}
            helperText="Assign analyzes kinds to a biomarker">
            <AutocompleteArrayInput fullWidth />
          </ReferenceArrayInput>
          <NumberInput source="numberMoles" min={0} sortable={false} lable="Number mole" fullWidth />
          <RichTextInput source="description" label="Description" fullWidth sortable={false} />
        </FormTab>

        <FormTab label="Greater Content">
          <TextField source="label" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextInput source="firstTypeCard.greaterLabel" label="Greater Label" fullWidth validate={required()} />
          <TextInput
            source="firstTypeCard.greaterDescription"
            label="Greater Description"
            fullWidth
            validate={required()}
          />
          <RichTextInput source="firstTypeCard.greaterContent" label="Greater Content" validate={required()} />
        </FormTab>
        <FormTab label="Lower Content">
          <TextField source="label" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextInput source="firstTypeCard.lowerLabel" label="Lower Label" fullWidth validate={required()} />
          <TextInput
            source="firstTypeCard.lowerDescription"
            label="Lower Description"
            fullWidth
            validate={required()}
          />
          <RichTextInput source="firstTypeCard.lowerContent" label="Lower Content" validate={required()} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
