import { PromocodePeriodEnum } from 'api/generated';
import React, { FC, ReactElement } from 'react';
import { Filter, ResourceComponentProps, SelectInput, TextInput } from 'react-admin';
import { ResourceName } from 'types/resources';

export const PromocodesFilter: FC<ResourceComponentProps> = (props): ReactElement => (
  <Filter {...props}>
    <TextInput key="name" source={`${ResourceName.PROMOCODE}.filter`} label="Поиск по имени" alwaysOn />
    <SelectInput
      key="period"
      source={`${ResourceName.PROMOCODE}.period`}
      label="Период действия"
      alwaysOn
      emptyText="Все"
      choices={[
        { id: PromocodePeriodEnum.Day, name: 'День' },
        { id: PromocodePeriodEnum.Month, name: 'Месяц' },
        { id: PromocodePeriodEnum.ThreeMonths, name: 'Три месяца' },
        { id: PromocodePeriodEnum.SixMonths, name: 'Шесть месяцев' },
        { id: PromocodePeriodEnum.Year, name: 'Год' },
      ]}
    />
  </Filter>
);
