import { useFieldsStyles } from 'assets/styles/common';
import { ShowRecordButton } from 'components';
import { FC, ReactElement } from 'react';
import {
  ChipField,
  Datagrid,
  DateField,
  Filter,
  List,
  ListProps,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  ResourceComponentProps,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import { ResourceName } from 'types/resources';

const CustomFilter: FC<ResourceComponentProps> = (props): ReactElement => (
  <Filter {...props}>
    <TextInput key="filter" source={`${ResourceName.BIOMARKERS}.filter`} label="Search" alwaysOn />
  </Filter>
);

export const BiomarkersList: FC<ListProps> = (props) => {
  const { textField, numberField } = useFieldsStyles();

  return (
    <List {...props} filters={<CustomFilter />} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <TextField source="name" className={textField} sortable={false} />
        <TextField source="label" className={textField} sortable={false} />
        <ReferenceField source="unitId" reference={ResourceName.UNITS}>
          <ChipField source="name" sortable={false} />
        </ReferenceField>
        <NumberField source="lowerNormMale" sortable={false} className={numberField} />
        <NumberField source="upperNormMale" sortable={false} className={numberField} />
        <NumberField source="lowerNormFemale" sortable={false} className={numberField} />
        <NumberField source="upperNormFemale" sortable={false} className={numberField} />
        <NumberField source="lowerNormConventional" sortable={false} className={numberField} />
        <NumberField source="upperNormConventional" sortable={false} className={numberField} />
        <NumberField source="lowerNormInvalidValue" sortable={false} className={numberField} />
        <NumberField source="upperNormInvalidValue" sortable={false} className={numberField} />
        <NumberField source="numberMoles" sortable={false} className={numberField} />
        <ReferenceArrayField label="Analysis Kinds" reference={ResourceName.ANALYSIS_KINDS} source="analysisKindsIds">
          <SingleFieldList>
            <ChipField source="label" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ShowRecordButton />
      </Datagrid>
    </List>
  );
};
