export const biomarkerValidator = (values: Record<string, string>): Record<string, string> => {
  const errors: Record<string, string> = {};

  const stringFields = ['label', 'name'];

  for (const stringField of stringFields) {
    if (!values[stringField]) {
      errors[stringField] = 'Field is required';
      continue;
    }
  }

  if (!values['unitId']) {
    errors['unitId'] = 'Field is required';
  }

  const numberFields = [
    'upperNormMale',
    'lowerNormMale',
    'upperNormFemale',
    'lowerNormFemale',
    'upperNormConventional',
    'lowerNormConventional',
    'upperNormInvalidValue',
    'lowerNormInvalidValue',
  ];

  numberFields.forEach((field) => {
    if (typeof values[field] === 'undefined' || values[field] === '' || values[field] === null) {
      errors[field] = 'Field is required';
      return;
    }
  });

  const postfixes = ['NormMale', 'NormFemale', 'NormConventional', 'NormInvalidValue'];
  for (const postfix of postfixes) {
    if (values[`lower${postfix}`] > values[`upper${postfix}`]) {
      errors[`lower${postfix}`] = 'Lower field should be lower than Upper field';
      continue;
    }
  }

  return errors;
};
