import React, { FC } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { ResourceName } from 'types/resources';

export const AnalysisKindShow: FC<ShowProps> = (props) => {
  return (
    <Show title="Analysis kind view" {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <NumberField source="id" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="label" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />
        </Tab>
        <Tab label="Biomarkers">
          <TextField source="label" sortable={false} />
          <ArrayField source="biomarkersIds" label="Biomarkers">
            <Datagrid>
              <ReferenceField label="Name" source="id" reference={ResourceName.BIOMARKERS}>
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField label="Label" source="id" reference={ResourceName.BIOMARKERS}>
                <TextField source="label" />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
