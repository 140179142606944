import { Biomarker } from 'api/generated';
import { ContentInput } from 'components/ContentInput/ContentInput';
import dataProvider from 'providers/data-provider';
import { FC, useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  EditProps,
  fetchEnd,
  fetchStart,
  FormTab,
  ImageField,
  ImageInput,
  Loading,
  minLength,
  ReferenceArrayInput,
  required,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';

import { CheckupsToolbar } from './CheckupsToolbar';

export const CheckupsEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [biomarkers, setBiomarkers] = useState([]);

  useEffect(() => {
    const fetchBiomarkers = async () => {
      dispatch(fetchStart());
      setLoading(true);
      const response = await dataProvider().getMany(ResourceName.BIOMARKERS);

      setBiomarkers(response.data);
    };
    fetchBiomarkers();
  }, []);

  useEffect(() => {
    dispatch(fetchEnd());
    setLoading(false);
  }, [biomarkers]);

  if (loading) return <Loading />;

  return (
    <Edit
      title="Checkup Edit"
      onSuccess={() => {
        notify('Checkup has been edited', 'success');
      }}
      mutationMode={'pessimistic'}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      {...props}>
      <TabbedForm toolbar={<CheckupsToolbar />}>
        <FormTab label="summary">
          <TextInput source="name" sortable={false} validate={[required(), minLength(4)]} />
          <ImageField source="avatar" label={'Current profile picture'} emptyText={'No Photo'} />
          <ImageInput source="avatar" label={'Change profile picture'} validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label="Description">
          <TextField source="name" sortable={false} />
          <ContentInput source={'description'} />
        </FormTab>
        <FormTab label="Biomarkers">
          <TextField source="name" sortable={false} />
          <ReferenceArrayInput
            reference="biomarkers"
            source="biomarkers"
            parse={(value: Biomarker[]) => value && value.map((v: Biomarker) => ({ id: v }))}
            format={(value: Biomarker[]) => value && value.map((v: Biomarker) => v.id)}>
            <AutocompleteArrayInput fullWidth optionText={'name'} choices={biomarkers} validate={required()} />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
