import { FC } from 'react';
import { ShowButton } from 'react-admin';
import { Record } from 'react-admin';

type ShowRecordButtonProps = {
  record?: Record;
  basePath?: string;
};

export const ShowRecordButton: FC<ShowRecordButtonProps> = ({ record, basePath }) => (
  <ShowButton basePath={basePath} label="Show" record={record} />
);
