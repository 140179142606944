import { AxiosError } from 'axios';
export const getErrorMessage = (error: AxiosError): string => {
  switch (typeof error?.response?.data?.message) {
    case 'string':
      return error?.response?.data?.message;
    case 'object':
      return error?.response?.data?.message[0];
    default:
      return 'Error occured';
  }
};
