import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import { FC } from 'react';
import { BooleanInput, Edit, EditProps, FormTab, SelectInput, TabbedForm, TextInput, useNotify } from 'react-admin';
import { getErrorMessage } from 'utils/errors-messages';

import { NutritionistsToolbar } from './NutritionistsToolbar';

const choices = [
  { id: ExpertEducationDocumentStatusEnum.NotSent, name: 'Not Sent' },
  { id: ExpertEducationDocumentStatusEnum.Checking, name: 'Checking' },
  { id: ExpertEducationDocumentStatusEnum.Approved, name: 'Approved' },
  { id: ExpertEducationDocumentStatusEnum.Declined, name: 'Declined' },
];

export const NutritionistEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  return (
    <Edit
      title="Expert Edit"
      {...props}
      onSuccess={() => {
        notify('Изменения успешно приняты', 'success');
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      mutationMode={'pessimistic'}>
      <TabbedForm toolbar={<NutritionistsToolbar />}>
        <FormTab label="summary">
          <TextInput source="name" sortable={false} />
          <TextInput source="spesialization" label="Speсialization" />
          <SelectInput source="educationDocumentStatus" fullWidth choices={choices} />
          <BooleanInput source="isApproved" label="Is Verified (EUL graduation)" fullWidth />
          <BooleanInput source="isShadowBanned" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
