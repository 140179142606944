import React, { FC } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';

import { CustomUnitSelectInput } from './components';
import styles from './styles.module.scss';
import { unitCoefficientsValidator, unitValidator } from './unit.validator';
import { UnitsToolbar } from './UnitsToolbar';

export const UnitEdit: FC<EditProps> = (props) => {
  const notify = useNotify();

  return (
    <Edit
      title="Unit Edit"
      onSuccess={() => {
        notify('Unit updated', 'success');
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm toolbar={<UnitsToolbar />} validate={unitValidator}>
        <TextInput source="name" fullWidth />

        <ArrayInput
          source="unitCoefficients"
          label="Coefficients"
          helperText="Add coefficients to another units if necessary"
          validate={unitCoefficientsValidator}>
          <SimpleFormIterator className={styles['form-iterator']}>
            {/* <ReferenceInput
              fullWidth
              label="Unit"
              source="compareUnitId"
              reference={ResourceName.UNITS}
              perPage={10000}
              validate={required('Field is required')}>
              <SelectInput
                source="compareUnitId"
                optionText="name"
                helperText="Choose unit to compare"
                placeholder="г/л"
                resettable
              />
            </ReferenceInput> */}

            <ReferenceInput
              fullWidth
              label="Unit"
              source="compareUnitId"
              reference={ResourceName.UNITS}
              perPage={10000}
              filter={{ name: 'г/дл' }}
              validate={required('Field is required')}>
              <CustomUnitSelectInput existingId={props.id} />
            </ReferenceInput>

            <NumberInput
              fullWidth
              source="coefficient"
              label="Coefficient"
              placeholder="0.01"
              helperText="Input coefficient"
              validate={required('Field is required')}
            />

            <BooleanInput source="useNumberMoles" defaultValue={false} label="Use number moles" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
