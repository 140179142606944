import { AnalysisKindsCreate } from './AnalysisKindsCreate';
import { AnalysisKindEdit } from './AnalysisKindsEdit';
import { AnalysisKindsList } from './AnalysisKindsList';
import { AnalysisKindShow } from './AnalysisKindsShow';

export default {
  list: AnalysisKindsList,
  create: AnalysisKindsCreate,
  show: AnalysisKindShow,
  edit: AnalysisKindEdit,
};
