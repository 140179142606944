import { UnitCreate } from './UnitCreate';
import { UnitEdit } from './UnitEdit';
import { UnitShow } from './UnitShow';
import { UnitsList } from './UnitsList';

export default {
  list: UnitsList,
  create: UnitCreate,
  show: UnitShow,
  edit: UnitEdit,
};
