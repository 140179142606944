import { FC } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';

import { AnalysisKindsToolbar } from './AnalysisKindsToolbar';
import { stringFieldsValidator } from './string-fields.validator';

export const AnalysisKindsCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();
  return (
    <Create onFailure={(err) => notify(getErrorMessage(err), 'error')} {...props}>
      <SimpleForm toolbar={<AnalysisKindsToolbar />} validate={stringFieldsValidator}>
        <TextInput source="name" label="Name" fullWidth helperText="Input name for analysis kind" />
        <TextInput source="label" label="Label" fullWidth helperText="Input label for analysis kind" />
        <ReferenceArrayInput
          validate={required()}
          label="Biomarkers"
          source="biomarkersIds"
          reference={ResourceName.BIOMARKERS}
          perPage={10000}
          helperText="Choose list of biomarkers assigned to an analysis kind">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
