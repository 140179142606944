import { useFieldsStyles } from 'assets/styles/common';
import { FC } from 'react';
import { Datagrid, DateField, Empty, List, ListProps, NumberField, TextField } from 'react-admin';
import { ResourceName } from 'types/resources';

import { PromocodesFilter } from './PromocodesFilter';
import { PromocodesListActions } from './PromocodesListActions';

export const PromocodesList: FC<ListProps> = (props) => {
  const { textField } = useFieldsStyles();
  return (
    <List
      {...props}
      filters={<PromocodesFilter />}
      empty={
        <>
          <PromocodesListActions />
          <Empty resource={ResourceName.PROMOCODE} />
        </>
      }
      actions={<PromocodesListActions />}
      bulkActionButtons={false}>
      <Datagrid>
        <NumberField source="name" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <TextField source="period" className={textField} sortable={false} />
      </Datagrid>
    </List>
  );
};
