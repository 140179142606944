import { ThirdTypeCardCreate } from './ThirdTypeCardCreate';
import { ThirdTypeCardEdit } from './ThirdTypeCardEdit';
import { ThirdTypeCardsList } from './ThirdTypeCardList';
import { ThirdTypeCardShow } from './ThirdTypeCardShow';

export default {
  list: ThirdTypeCardsList,
  edit: ThirdTypeCardEdit,
  show: ThirdTypeCardShow,
  create: ThirdTypeCardCreate,
};
