import RichTextInput, { RichTextInputProps } from 'ra-input-rich-text';
import React, { FC } from 'react';

interface ContentInputProps extends RichTextInputProps {
  source: string;
}

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  ['clean'], // remove formatting button
];

export const ContentInput: FC<ContentInputProps> = (props) => {
  const { source, ...restProps } = props;
  return <RichTextInput source={source} toolbar={toolbarOptions} {...restProps} />;
};
