import { CheckupsEdit } from './CheckupsEdit';
import { CheckupsList } from './CheckupsList';
import { CheckupsShow } from './CheckupsShow';
export { CheckupsToolbar } from './CheckupsToolbar';
import { CheckupsCreate } from './CheckupCreate';

export default {
  list: CheckupsList,
  create: CheckupsCreate,
  edit: CheckupsEdit,
  show: CheckupsShow,
};
