export const stringFieldsCardValidator = (values: Record<string, string>): Record<string, string> => {
  const errors: Record<string, string> = {};

  const stringFields = ['title', 'description'];

  stringFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Field is required';
      return;
    }
  });

  return errors;
};
