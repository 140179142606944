import { SecondTypeCardsBiomarkerOutsideNormZoneEnum } from 'api/generated';
import { ContentInput } from 'components';
import { FC } from 'react';
import {
  ArrayInput,
  Create,
  CreateProps,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';
import { biomarkersCardsValidator, stringFieldsCardValidator } from 'utils/validators/recommendation-cards';

import { SecondTypeCardToolbar } from './SecondTypeCardToolbar';
import styles from './styles.module.scss';

export const SecondTypeCardCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const outsideNormZoneChoices = Object.values(SecondTypeCardsBiomarkerOutsideNormZoneEnum).map(
    (outsideNormZoneValue) => ({
      id: outsideNormZoneValue,
      name: outsideNormZoneValue,
    }),
  );

  return (
    <Create
      onSuccess={(props) => {
        notify('Second type card created', 'success');
        redirect(`/${ResourceName.SECOND_TYPE_CARDS}/${props.data.id}/show`);
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      {...props}>
      <TabbedForm toolbar={<SecondTypeCardToolbar />} validate={stringFieldsCardValidator}>
        <FormTab label="summary">
          <TextInput source="title" label="Title" fullWidth helperText="Input title for recommendation" />

          <TextInput source="description" fullWidth helperText="Add short description" />
        </FormTab>
        <FormTab label="Content">
          <ContentInput source="content" />
        </FormTab>
        <FormTab label="Biomarkers">
          <ArrayInput
            source="stcBiomarkers"
            label="Biomarkers"
            initialValue={[
              {
                biomarkerId: null,
                outsideNormZone: null,
                maleNormField: null,
                femaleNormField: null,
              },
            ]}
            validate={biomarkersCardsValidator}>
            <SimpleFormIterator className={styles['form-iterator']}>
              <ReferenceInput
                label="Biomarker Name"
                source="biomarkerId"
                reference={ResourceName.BIOMARKERS}
                perPage={10000}>
                <SelectInput optionText="name" source="biomarkerId" defaultValue={null} />
              </ReferenceInput>
              <SelectInput
                label="Outside Norm Zone"
                source="outsideNormZone"
                choices={outsideNormZoneChoices}
                defaultValue={null}
              />
              <NumberInput source="maleNormField" label="Male Norm Field" min={0} defaultValue={null} />
              <NumberInput source="femaleNormField" label="Female Norm Field" min={0} defaultValue={null} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
