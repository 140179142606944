import axios from 'axios';
export const uploadPhotoToS3 = async (signedUrl: string, file: File): Promise<string> => {
  await axios.put(signedUrl, file);
  return getPhotoUrl(signedUrl);
};

const getPhotoUrl = (signedUrl: string): string => {
  const photoURL = new URL(signedUrl);
  return `${photoURL.origin}${photoURL.pathname}`;
};
