import { FC } from 'react';
import {
  ArrayInput,
  Create,
  CreateProps,
  FormTab,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';
import { nutritionPlanValidator } from 'utils/validators/recommendation-cards/nutrition-plan.validator';

import { NutritionPlanToolbar } from './NutritionPlanToolbar';
import { RichTextObjectArrayInput } from './RichTextObjectArrayInput';

export const NutritionPlanCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      onSuccess={(props) => {
        notify('Nutrition Plan created', 'success');
        redirect(`/${ResourceName.NUTRITION_PLANS}/${props.data.id}/show`);
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      {...props}>
      <TabbedForm toolbar={<NutritionPlanToolbar />} validate={nutritionPlanValidator}>
        <FormTab label="summary">
          <TextInput source="title" sortable={false} fullWidth multiline helperText="Input title for Nutrition Plan" />
          <TextInput source="description" sortable={false} fullWidth multiline helperText="Add short description" />
          <TextInput
            source="additionalInfo"
            sortable={false}
            helperText="Add information about plan expectations"
            fullWidth
            multiline
          />
          <ArrayInput source="goals" initialValue={['']}>
            <SimpleFormIterator>
              <TextInput source="" fullWidth multiline label="" validate={required('Field is required')} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Include Products">
          <RichTextObjectArrayInput source="includeProducts" />
        </FormTab>
        <FormTab label="Exclude Products">
          <RichTextObjectArrayInput source="excludeProducts" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
