import { AnalysisKindsBiomarkersCreateDto } from 'api/generated';
import RichTextInput from 'ra-input-rich-text';
import React, { FC } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';
import { biomarkerValidator } from 'utils/validators/recommendation-cards/biomarker.validator';

import { BiomarkerToolbar } from './BiomarkerToolbar';
import { useTabStyles } from './styles';

export const BiomarkerCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();
  const { formTab } = useTabStyles();
  const redirect = useRedirect();

  return (
    <Create
      title="Biomarker"
      onSuccess={(props) => {
        notify('Biomarker created', 'success');
        redirect(`/${ResourceName.BIOMARKERS}/${props.data.id}/show`);
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      {...props}>
      <TabbedForm toolbar={<BiomarkerToolbar />} validate={biomarkerValidator}>
        <FormTab label="summary" contentClassName={formTab}>
          <TextInput source="label" sortable={false} fullWidth />
          <TextInput source="name" sortable={false} fullWidth />
          <RichTextInput source="description" label="Description" />
          <ReferenceInput
            fullWidth
            allowEmpty
            label="Unit"
            source="unitId"
            reference={ResourceName.UNITS}
            perPage={10000}>
            <SelectInput source="unitId" helperText="Assign unit to a biomarker" fullWidth />
          </ReferenceInput>
          <NumberInput source="lowerNormMale" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormMale" sortable={false} min={0} fullWidth />
          <NumberInput source="lowerNormFemale" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormFemale" sortable={false} min={0} fullWidth />
          <NumberInput source="lowerNormConventional" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormConventional" sortable={false} min={0} fullWidth />
          <NumberInput source="lowerNormInvalidValue" sortable={false} min={0} fullWidth />
          <NumberInput source="upperNormInvalidValue" sortable={false} min={0} fullWidth />
          <ReferenceArrayInput
            label="Analysis Kinds"
            source="analysisKindsBiomarkers"
            reference={ResourceName.ANALYSIS_KINDS}
            perPage={10000}
            helperText="Assign analyzes kinds to a biomarker"
            parse={(val: string[]) => val && val.map((val) => ({ analysisKindId: val }))}
            format={(val: AnalysisKindsBiomarkersCreateDto[]) => val && val.map((val) => val.analysisKindId)}>
            <AutocompleteArrayInput fullWidth />
          </ReferenceArrayInput>
          <NumberInput source="numberMoles" min={0} sortable={false} lable="Number mole" fullWidth />
        </FormTab>
        <FormTab label="Greater Content">
          <TextInput
            source="firstTypeCard.greaterLabel"
            label="Greater Label"
            fullWidth
            helperText="Fill this field or it will be filled automatically with default content"
          />
          <TextInput
            source="firstTypeCard.greaterDescription"
            label="Greater Description"
            fullWidth
            helperText="Fill this field or it will be filled automatically with default content"
          />
          <RichTextInput
            source="firstTypeCard.greaterContent"
            label="Greater Content"
            helperText="Fill this field or it will be filled automatically with default content"
          />
        </FormTab>
        <FormTab label="Lower Content">
          <TextInput
            source="firstTypeCard.lowerLabel"
            label="Lower Label"
            helperText="Fill this field or it will be filled automatically with default content"
            fullWidth
          />
          <TextInput
            source="firstTypeCard.lowerDescription"
            label="Lower Description"
            helperText="Fill this field or it will be filled automatically with default content"
            fullWidth
          />
          <RichTextInput
            source="firstTypeCard.lowerContent"
            label="Lower Content"
            helperText="Fill this field or it will be filled automatically with default content"
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
