import { Input, MenuItem, Select, Toolbar } from '@material-ui/core';
import { PromocodePeriodEnum } from 'api/generated';
import * as React from 'react';
import { useState } from 'react';

import { GeneratePromocodesButton } from './GeneratePromocodesButton';

const periodChoices = [
  { label: 'День', value: PromocodePeriodEnum.Day },
  { label: 'Месяц', value: PromocodePeriodEnum.Month },
  { label: 'Три месяца', value: PromocodePeriodEnum.ThreeMonths },
  { label: 'Шесть месяцев', value: PromocodePeriodEnum.SixMonths },
  { label: 'Год', value: PromocodePeriodEnum.Year },
];

export const PromocodesListActions: React.FC = () => {
  const [count, setCount] = useState<number>(1);
  const [period, setPeriod] = useState<PromocodePeriodEnum>(PromocodePeriodEnum.Day);

  return (
    <Toolbar style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
      <Input
        value={Math.abs(count)}
        onChange={(event) => {
          const value = event.target.value;
          if (+value < 0 || +value > 300) return;
          setCount(+value);
        }}
        type="number"
        defaultValue={1}
        style={{ width: '50px' }}
        inputProps={{ min: 1, max: 300 }}
      />
      <Select
        value={period}
        onChange={(event) => setPeriod(event.target.value as PromocodePeriodEnum)}
        style={{ width: '142px' }}>
        {periodChoices.map(({ label, value }, i) => (
          <MenuItem key={i} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <GeneratePromocodesButton count={count} period={period} />
    </Toolbar>
  );
};
