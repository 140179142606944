import { useFieldsStyles } from 'assets/styles/common';
import { ShowRecordButton } from 'components';
import { FC } from 'react';
import { Datagrid, DateField, List, ListProps, NumberField, TextField } from 'react-admin';

import { NutritionistsFilter } from './NutritionistFilters';

export const NutritionistsList: FC<ListProps> = (props) => {
  const { textField } = useFieldsStyles();
  return (
    <List {...props} filters={<NutritionistsFilter />} bulkActionButtons={false}>
      <Datagrid>
        <NumberField source="id" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <DateField source="updatedAt" sortable={false} />
        <TextField source="name" className={textField} sortable={false} />
        <TextField source="email" className={textField} sortable={false} />
        <DateField source="birthday" className={textField} sortable={false} />
        <TextField source="spesialization" label="Speсialization" className={textField} sortable={false} />
        <TextField source="educationDocumentStatus" className={textField} sortable={false} />

        <ShowRecordButton />
      </Datagrid>
    </List>
  );
};
