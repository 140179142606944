import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { SaveButton, Toolbar, ToolbarProps } from 'react-admin';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const NutritionistsToolbar: FC<ToolbarProps> = (props) => {
  // const nutritionistRecord = props.record as Nutritionist;
  // const nutritionist = nutritionistRecord.name || nutritionistRecord.email || nutritionistRecord.id;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton label="Save" disabled={props.pristine} />
      {/* <DeleteButton
        confirmTitle="Удаление нутрициолога"
        confirmContent={`Вы действительно хотите удалить нутрициолога "${nutritionist}"?`}
        label="Удалить"
        mutationMode="optimistic"
      /> */}
    </Toolbar>
  );
};
