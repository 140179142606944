import React, { FC } from 'react';
import {
  BooleanField,
  DateField,
  ImageField,
  NumberField,
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

const TextArrayField: FC<any> = ({ record, source }) => {
  const array = record[source];
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />;
  } else {
    return (
      <>
        {array.map((item: any) => (
          <>
            <a key={item} href={item}>
              {item}
            </a>
            <br />
          </>
        ))}
      </>
    );
  }
};
TextArrayField.defaultProps = { addLabel: true };

export const NutritionistShow: FC<ShowProps> = (props) => {
  return (
    <Show title="Expert view" {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <NumberField source="id" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />

          <TextField source="name" sortable={false} />
          <BooleanField source="isApproved" label="Is Verified (EUL graduation)" />
          <BooleanField source="isShadowBanned" label="Is Shadow Banned" />
          <ImageField source="avatar" sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="spesialization" label="Speсialization" />

          <DateField source="birthday" sortable={false} />
          <RichTextField source="educationDocumentStatus" sortable={false} />
          <TextArrayField source="educationDocuments"></TextArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
