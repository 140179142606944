import { ReactElement } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';

interface CustomProps extends SelectInputProps {
  existingId?: string;
}

export const CustomUnitSelectInput = (props: CustomProps): ReactElement => {
  const filteredChoices = props?.choices?.filter((choice: any) => choice.id !== props.existingId);

  return (
    <SelectInput
      source="compareUnitId"
      optionText="name"
      helperText="Choose unit to compare"
      placeholder="г/л"
      resettable
      {...props}
      choices={filteredChoices}
    />
  );
};
