import { makeStyles } from '@material-ui/core/styles';
import { AnalysisKindEntity } from 'api/generated';
import { FC } from 'react';
import { DeleteButton, SaveButton, Toolbar, ToolbarProps } from 'react-admin';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const AnalysisKindsToolbar: FC<ToolbarProps> = (props) => {
  const analysisKindRecord = props.record as AnalysisKindEntity;
  const { label } = analysisKindRecord;

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton label="Save" disabled={props.pristine} />
      <DeleteButton
        confirmTitle="Recommendation remove"
        confirmContent={`Are you sure you want to delete item "${label}"?`}
        label="Remove"
        mutationMode="optimistic"
      />
    </Toolbar>
  );
};
