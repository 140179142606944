import { SecondTypeCardCreate } from './SecondTypeCardCreate';
import { SecondTypeCardEdit } from './SecondTypeCardEdit';
import { SecondTypeCardShow } from './SecondTypeCardShow';
import { SecondTypeCardsList } from './SecondTypeCardsList';

export default {
  list: SecondTypeCardsList,
  edit: SecondTypeCardEdit,
  show: SecondTypeCardShow,
  create: SecondTypeCardCreate,
};
