import { FC } from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  EditProps,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
} from 'react-admin';
import { ResourceName } from 'types/resources';
import { getErrorMessage } from 'utils/errors-messages';

import { AnalysisKindsToolbar } from './AnalysisKindsToolbar';
import { stringFieldsValidator } from './string-fields.validator';

export const AnalysisKindEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Edit
      title="Analysis Kind Edit"
      transform={(data) => ({ ...data, biomarkersIds: data.biomarkersIds.map((v: { id: string }) => v.id) })}
      onSuccess={() => {
        notify('Analysis Kind updated', 'success');
        refresh();
      }}
      onFailure={(err) => notify(getErrorMessage(err), 'error')}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm toolbar={<AnalysisKindsToolbar />} validate={stringFieldsValidator}>
        <TextInput source="name" fullWidth />
        <TextInput source="label" fullWidth />

        <ReferenceArrayInput
          label="Biomarkers"
          source="biomarkersIds"
          reference={ResourceName.BIOMARKERS}
          perPage={10000}
          validate={required()}
          helperText="Choose list of biomarkers assigned to an analysis kind"
          format={(value: { id: string }[]) => value && value.map((v) => v.id)}
          parse={(value: string[]) => value && value.map((v) => ({ id: v }))}>
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
