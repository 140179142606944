export enum StorageKeys {
  RA_LANGUAGE = 'ra_language',
}

export const getLanguageFromStorage = (): string => {
  const jsonItemFromLocaleStorage = localStorage.getItem(`${StorageKeys.RA_LANGUAGE}`);
  return jsonItemFromLocaleStorage && JSON.parse(jsonItemFromLocaleStorage);
};

export const setLanguageToStorage = (payload: string) => {
  return localStorage.setItem(`${StorageKeys.RA_LANGUAGE}`, JSON.stringify(payload));
};

export const resetLanguageStorage = () => {
  return localStorage.removeItem(`${StorageKeys.RA_LANGUAGE}`);
};
