import { useFieldsStyles } from 'assets/styles/common';
import { ShowRecordButton } from 'components';
import { FC } from 'react';
import { Datagrid, DateField, List, ListProps, NumberField, TextField, TextInput } from 'react-admin';
import { ResourceName } from 'types/resources';

const SearchInput = (
  <TextInput key="filter" source={`${ResourceName.THIRD_TYPE_CARDS}.filter`} label="Search" alwaysOn />
);

export const ThirdTypeCardsList: FC<ListProps> = (props) => {
  const { textField } = useFieldsStyles();
  return (
    <List {...props} filters={[SearchInput]} bulkActionButtons={false}>
      <Datagrid rowClick={'show'}>
        <NumberField source="id" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <TextField source="title" className={textField} sortable={false} />
        <TextField source="description" className={textField} sortable={false} />
        <ShowRecordButton />
      </Datagrid>
    </List>
  );
};
