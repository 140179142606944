export enum ResourceName {
  EXPERTS = 'admins/experts',
  CHECKUPS = 'admins/checkups',
  PAGINATED_BIOMARKERS = 'admins/paginated-biomarkers',
  BIOMARKERS = 'admins/biomarkers',
  SECOND_TYPE_CARDS = 'admins/recommendation-cards/second-type-cards',
  THIRD_TYPE_CARDS = 'admins/recommendation-cards/third-type-cards',
  PAGINATED_ANALYSIS_KINDS = 'admins/analysis-kinds/paginated',
  ANALYSIS_KINDS = 'admins/analysis-kinds',
  NUTRITION_PLANS = 'admins/nutrition-plans',
  UNITS = 'admins/units',
  PAGINATED_UNITS = 'admins/units/paginated',
  PROMOCODE = 'admins/promocodes/paginated',
  CHATS = 'admins/chats',
}
