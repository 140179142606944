import {
  CreateSecondTypeCardBiomarkersDto,
  PromocodePeriodEnum,
  ThirdTypeCard,
  ThirdTypeCardRatiosDto,
} from 'api/generated';
import { AxiosResponse } from 'axios';
import { stringify } from 'querystring';
import { DataProvider } from 'ra-core';
import { CreateParams, DeleteParams, GetListParams, GetManyParams, UpdateParams } from 'react-admin';
import { http } from 'services/http';
import { getLanguageFromStorage } from 'services/storageService';

import { api } from '../api';
import { CreateBiomarkerDto, FirstTypeCardDto, SecondTypeCardsBiomarker } from '../api/generated';
import { ResourceName } from '../types/resources';
import { isEmptyRecurse } from '../utils/isEmpryResource';
import { uploadPhotoToS3 } from '../utils/uploadPhotoToS3';

interface CustomGetListParams extends GetListParams {
  filter: {
    [ResourceName.EXPERTS]?: {
      filter?: string;
      documentStatus?: string;
    };
    [ResourceName.CHECKUPS]?: {
      filter?: string;
    };
    [ResourceName.SECOND_TYPE_CARDS]?: {
      filter?: string;
    };
    [ResourceName.BIOMARKERS]?: {
      filter?: string;
    };
    [ResourceName.THIRD_TYPE_CARDS]?: {
      filter?: string;
    };
    [ResourceName.ANALYSIS_KINDS]?: {
      filter?: string;
    };
    [ResourceName.NUTRITION_PLANS]?: {
      filter?: string;
    };
    [ResourceName.UNITS]?: {
      filter?: string;
    };
    [ResourceName.PROMOCODE]?: {
      filter?: string;
      period?: PromocodePeriodEnum;
    };
    [ResourceName.CHATS]?: {
      filter?: string;
    };
  };
}

type CustomCreateBiomarkerDto = Omit<CreateBiomarkerDto, 'ftcValues'> & {
  firstTypeCard: FirstTypeCardDto;
};

export interface CustomDataProvider extends DataProvider {
  updateMany: () => Promise<any>;
  create: (resource: ResourceName | string, params: CreateParams) => Promise<any>;
  delete: (resource: ResourceName | string, params: DeleteParams) => Promise<AxiosResponse>;
  deleteMany: () => Promise<any>;
  update: (resource: ResourceName | string, params: UpdateParams) => Promise<any>;
  getMany: (resource: ResourceName | string, params?: GetManyParams) => Promise<AxiosResponse>;
  getManyReference: () => Promise<any>;
  createMany: (resource: ResourceName | string, params: CreateParams) => Promise<AxiosResponse>;
}

const updateOneMapping: Record<ResourceName | string, (id: string, data: any) => Promise<AxiosResponse>> = {
  [ResourceName.EXPERTS]: api.AdminsExpertsApi.adminExpertsControllerUpdateExpert.bind(api.AdminsApi),
  [ResourceName.CHECKUPS]: api.AdminsCheckupsApi.adminCheckupsControllerUpdateCheckup.bind(api.AdminsApi),
  [ResourceName.SECOND_TYPE_CARDS]:
    api.AdminsSecondTypeCardsApi.adminSecondTypeCardsControllerUpdateSecondTypeCard.bind(api.AdminsApi),
  [ResourceName.BIOMARKERS]: api.AdminsBiomarkersApi.adminBiomarkersControllerUpdateBiomarker.bind(api.AdminsApi),
  [ResourceName.THIRD_TYPE_CARDS]: api.AdminsThirdTypeCardsApi.adminThirdTypeCardsControllerUpdateThirdTypeCard.bind(
    api.AdminsApi,
  ),
  [ResourceName.NUTRITION_PLANS]: api.AdminsNutritionPlansApi.adminNutritionPlansControllerUpdateNutritionPlan.bind(
    api.AdminsApi,
  ),
  [ResourceName.ANALYSIS_KINDS]: api.AdminsAnalysisKindsApi.adminAnalysisKindsControllerUpdateAnalysisKind.bind(
    api.AdminsApi,
  ),
  [ResourceName.UNITS]: api.AdminsUnitsApi.adminUnitsControllerUpdateUnit.bind(api.AdminsApi),
};

const deleteOneMapping: Record<ResourceName | string, (id: string) => Promise<AxiosResponse>> = {
  [ResourceName.CHECKUPS]: api.AdminsCheckupsApi.adminCheckupsControllerDeleteCheckup.bind(api.AdminsApi),
  [ResourceName.SECOND_TYPE_CARDS]:
    api.AdminsSecondTypeCardsApi.adminSecondTypeCardsControllerRemoveSecondTypeCard.bind(api.AdminsApi),
  [ResourceName.BIOMARKERS]: api.AdminsBiomarkersApi.adminBiomarkersControllerRemoveBiomarker.bind(api.AdminsApi),
  [ResourceName.THIRD_TYPE_CARDS]: api.AdminsThirdTypeCardsApi.adminThirdTypeCardsControllerDeleteThirdTypeCard.bind(
    api.AdminsApi,
  ),
  [ResourceName.NUTRITION_PLANS]: api.AdminsNutritionPlansApi.adminNutritionPlansControllerDeleteNutritionPlan.bind(
    api.AdminsApi,
  ),
  [ResourceName.ANALYSIS_KINDS]: api.AdminsAnalysisKindsApi.adminAnalysisKindsControllerDeleteAnalysisKind.bind(
    api.AdminsApi,
  ),
  [ResourceName.UNITS]: api.AdminsUnitsApi.adminUnitsControllerDeleteUnit.bind(api.AdminsApi),
};

const getSignedUrlMapping: Record<ResourceName | string, (data: any) => Promise<AxiosResponse>> = {
  [ResourceName.CHECKUPS]: api.AdminsCheckupsApi.adminCheckupsControllerGetCheckupImageSignedUrlToUpload.bind(
    api.AdminsApi,
  ),
};

const createManyMapping: Record<ResourceName | string, (data: any) => Promise<AxiosResponse>> = {
  [ResourceName.PROMOCODE]: api.AdminsPromocodesApi.adminPromocodesControllerGeneratePromocode.bind(
    api.AdminsPromocodesApi,
  ),
};

export default (): CustomDataProvider => ({
  getList: (resource, params: CustomGetListParams) => {
    let res = '';

    switch (resource) {
      case ResourceName.BIOMARKERS:
        res = ResourceName.PAGINATED_BIOMARKERS;
        break;
      case ResourceName.ANALYSIS_KINDS:
        res = ResourceName.PAGINATED_ANALYSIS_KINDS;
        break;
      case ResourceName.UNITS:
        res = ResourceName.PAGINATED_UNITS;
        break;
      case 'biomarkers':
        res = ResourceName.PAGINATED_BIOMARKERS;
        break;
      default:
        break;
    }

    const language = getLanguageFromStorage();

    const { page, perPage } = params.pagination;
    const filter = params.filter;
    const query: Record<string, any> = {};

    if (params.pagination) {
      query['page'] = page;
      query['limit'] = perPage;
    }

    switch (resource) {
      case ResourceName.EXPERTS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        if (filter[resource]?.documentStatus) {
          query['documentStatus'] = filter[resource]?.documentStatus;
        }
        break;
      case ResourceName.CHECKUPS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      case ResourceName.SECOND_TYPE_CARDS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      case ResourceName.THIRD_TYPE_CARDS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      case ResourceName.ANALYSIS_KINDS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      case ResourceName.BIOMARKERS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      case ResourceName.NUTRITION_PLANS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      case ResourceName.UNITS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      case ResourceName.PROMOCODE:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        if (filter[resource]?.period) {
          query['promocode'] = filter[resource]?.period;
        }
        break;
      case ResourceName.CHATS:
        if (filter[resource]?.filter) {
          query['filter'] = filter[resource]?.filter;
        }
        break;
      default:
        break;
    }

    const urlWithQuery = `${res ? res : resource}?l=${language}&${stringify(query)}`;

    return http
      .get(urlWithQuery)
      .then(({ data }) => {
        return {
          data: data.data,
          total: data.total,
        };
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  },

  getOne: (resource, params) => {
    const language = getLanguageFromStorage();

    let res = '';

    switch (resource) {
      case ResourceName.PAGINATED_BIOMARKERS:
        res = ResourceName.BIOMARKERS;
        break;
      case ResourceName.PAGINATED_ANALYSIS_KINDS:
        res = ResourceName.ANALYSIS_KINDS;
        break;
      default:
        break;
    }

    const urlWithQuery = `${res ? res : resource}/${params.id}?l=${language}`;

    return http
      .get(urlWithQuery)
      .then(({ data }) => {
        switch (resource) {
          case ResourceName.ANALYSIS_KINDS:
            return { data: { ...data, biomarkersIds: data.biomarkersIds.map((id: string) => ({ id })) } };
          default:
            return { data: data };
        }
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  },

  update: async (resource, params) => {
    switch (resource) {
      case ResourceName.EXPERTS: {
        return updateOneMapping[resource](params.id as string, params.data);
      }
      case ResourceName.ANALYSIS_KINDS: {
        const language = getLanguageFromStorage();
        const urlWithQuery = `${resource}/${params.id}/?l=${language}`;

        return http
          .put(urlWithQuery, { ...params.data })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }

      case ResourceName.NUTRITION_PLANS: {
        const language = getLanguageFromStorage();
        const urlWithQuery = `${resource}/${params.id}/?l=${language}`;

        return http
          .put(urlWithQuery, { ...params.data })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }

      case ResourceName.UNITS: {
        const language = getLanguageFromStorage();
        const urlWithQuery = `${resource}/${params.id}/?l=${language}`;

        return http
          .put(urlWithQuery, { ...params.data })
          .then(({ data }) => ({
            data,
            log: console.log(data),
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }

      case ResourceName.CHECKUPS: {
        const language = getLanguageFromStorage();

        const urlWithQuery = `${resource}/${params.id}/?l=${language}`;

        const query: Record<string, unknown> = {};
        if (params.data.biomarkers.length > 0) {
          params.data.biomarkers = params.data.biomarkers.map((biomarker: any) => biomarker.id);
        }
        if (params?.data?.avatar?.rawFile) {
          const filename = params.data.avatar.rawFile.name;
          const {
            data: { signedUrl },
          } = await getSignedUrlMapping[resource]({ filename });
          const avatar = await uploadPhotoToS3(signedUrl, params.data.avatar.rawFile);
          query['avatar'] = avatar;
        } else {
          query['avatar'] = params.data.avatar;
        }
        query['name'] = params.data.name;
        query['description'] = params.data.description;
        query['biomarkers'] = params.data.biomarkers;

        return http
          .patch(urlWithQuery, { ...query })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }
      case ResourceName.SECOND_TYPE_CARDS: {
        const language = getLanguageFromStorage();

        const urlWithQuery = `${resource}/${params.id}/?l=${language}`;

        const { stcBiomarkers, ...secondTypeCard } = params.data;
        const newStcBiomarkers: Array<CreateSecondTypeCardBiomarkersDto> = stcBiomarkers.map(
          (stcBiomarker: SecondTypeCardsBiomarker) => ({
            biomarkerId: stcBiomarker.biomarkerId,
            outsideNormZone: stcBiomarker.outsideNormZone,
            maleNormField: stcBiomarker.maleNormField,
            femaleNormField: stcBiomarker.femaleNormField,
          }),
        );
        return http
          .patch(urlWithQuery, { stcBiomarkers: newStcBiomarkers, ...secondTypeCard })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }
      case ResourceName.THIRD_TYPE_CARDS: {
        const language = getLanguageFromStorage();

        const urlWithQuery = `${resource}/${params.id}/?l=${language}`;

        const { ratios } = params.data;
        if (!ratios.length) {
          params.data.ratios = null;
        }

        const biomarkersScore = (params.data as ThirdTypeCard).ttcBiomarkers?.reduce(
          (acc, biomarker) => acc + (biomarker?.score || 0),
          0,
        );

        const totalScore = params.data?.ratios
          ? params.data?.ratios?.reduce((acc: any, ratio: any) => (acc || 0) + (ratio?.score || 0), biomarkersScore)
          : biomarkersScore;

        if (totalScore <= 10) {
          return http
            .put(urlWithQuery, params.data)
            .then(({ data }) => ({
              data,
            }))
            .catch((error) => {
              throw new Error(error.response.data.message);
            });
        } else {
          return Promise.reject({ response: { data: { message: 'total score must be less 10' } } });
        }
      }
      case ResourceName.BIOMARKERS: {
        const language = getLanguageFromStorage();

        const urlWithQuery = `${resource}/${params.id}/?l=${language}`;

        const {
          firstTypeCard: {
            lowerContent,
            lowerLabel,
            lowerDescription,
            greaterContent,
            greaterDescription,
            greaterLabel,
          },
          analysisKindsIds,
          analysisKinds,
          ...biomarker
        } = params.data;

        const firstTypeCardDto: FirstTypeCardDto = {
          lowerContent,
          lowerLabel,
          lowerDescription,
          greaterContent,
          greaterDescription,
          greaterLabel,
        };
        const analysisKindsBiomarkers = analysisKindsIds.map((analysisKindId: string) => ({ analysisKindId }));

        return http
          .patch(urlWithQuery, {
            ftcValues: firstTypeCardDto,
            ...biomarker,
            analysisKindsBiomarkers,
          })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }
    }

    return updateOneMapping[resource](params.id as string, params.data);
  },

  getMany: (resource) => {
    const language = getLanguageFromStorage();

    let res = '';

    if (resource === 'biomarkers') {
      res = ResourceName.BIOMARKERS;
    }

    const urlWithQuery = `${res ? res : resource}?l=${language}`;

    return http
      .get(urlWithQuery)
      .then((data) => data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  },

  getManyReference: () => Promise.resolve(),

  updateMany: () => Promise.resolve(),

  create: async (resource, params) => {
    const language = getLanguageFromStorage();
    const urlWithQuery = `${resource}?l=${language}`;

    switch (resource) {
      case ResourceName.SECOND_TYPE_CARDS: {
        return http
          .post(urlWithQuery, { ...params.data })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }

      case ResourceName.UNITS: {
        return http
          .post(urlWithQuery, { ...params.data })
          .then(({ data }) => ({
            data,
            log: console.log(data),
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }
      case ResourceName.NUTRITION_PLANS: {
        return http
          .post(urlWithQuery, { ...params.data })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }
      case ResourceName.CHECKUPS: {
        const query: Record<string, unknown> = {};
        if (params?.data?.avatar) {
          const filename = params.data.avatar.rawFile.name;
          const {
            data: { signedUrl },
          } = await getSignedUrlMapping[resource]({ filename });
          const avatar = await uploadPhotoToS3(signedUrl, params.data.avatar.rawFile);
          query['avatar'] = avatar;
        }
        query['name'] = params.data.name;
        query['description'] = params.data.description;

        return http
          .post(urlWithQuery, { ...query })
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }
      case ResourceName.BIOMARKERS: {
        const customCreateBiomarkerDto: CustomCreateBiomarkerDto = params.data;
        let firstTypeCardDto: FirstTypeCardDto = {};

        if (customCreateBiomarkerDto?.firstTypeCard) {
          const { lowerContent, lowerLabel, lowerDescription, greaterContent, greaterDescription, greaterLabel } =
            customCreateBiomarkerDto?.firstTypeCard;
          firstTypeCardDto = {
            lowerContent,
            lowerLabel,
            lowerDescription,
            greaterContent,
            greaterDescription,
            greaterLabel,
          };
        }

        const {
          lowerNormConventional,
          upperNormConventional,
          lowerNormFemale,
          upperNormFemale,
          lowerNormMale,
          upperNormMale,
          lowerNormInvalidValue,
          upperNormInvalidValue,
        } = customCreateBiomarkerDto;
        const createBiomarkerDto: CreateBiomarkerDto = {
          ftcValues: firstTypeCardDto,
          ...customCreateBiomarkerDto,
          lowerNormConventional: Number(lowerNormConventional),
          upperNormConventional: Number(upperNormConventional),
          lowerNormFemale: Number(lowerNormFemale),
          upperNormFemale: Number(upperNormFemale),
          upperNormMale: Number(upperNormMale),
          lowerNormMale: Number(lowerNormMale),
          lowerNormInvalidValue: Number(lowerNormInvalidValue),
          upperNormInvalidValue: Number(upperNormInvalidValue),
        };

        return http
          .post(urlWithQuery, createBiomarkerDto)
          .then(({ data }) => ({
            data,
          }))
          .catch((error) => {
            throw new Error(error.response.data.message);
          });
      }
      case ResourceName.THIRD_TYPE_CARDS: {
        const { ratios } = params.data;
        const newRatios: any[] = [];

        (ratios as ThirdTypeCardRatiosDto[]).map((value) => {
          if (!isEmptyRecurse(value)) {
            newRatios.push(value);
          }
        });

        const biomarkersScore = (params.data as ThirdTypeCard).ttcBiomarkers?.reduce(
          (acc, biomarker) => acc + (biomarker?.score || 0),
          0,
        );

        const totalScore = params.data?.ratios
          ? params.data?.ratios?.reduce((acc: any, ratio: any) => (acc || 0) + (ratio?.score || 0), biomarkersScore)
          : biomarkersScore;

        if (totalScore <= 10) {
          return http
            .post(urlWithQuery, { ...params.data, ratios: newRatios })
            .then(({ data }) => ({
              data,
            }))
            .catch((error) => {
              throw new Error(error.response.data.message);
            });
        } else {
          return Promise.reject({ response: { data: { message: 'total score must be less 10' } } });
        }
      }
    }

    http
      .post(urlWithQuery, { ...params.data })
      .then(({ data }) => ({
        data,
      }))
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  },

  delete: (resource, params) => {
    return deleteOneMapping[resource](params.id as string);
  },

  deleteMany: () => Promise.resolve(),

  createMany: (resource, params) => {
    return createManyMapping[resource](params);
  },
});
