import { BiomarkerCreate } from './BiomarkerCreate';
import { BiomarkerEdit } from './BiomarkerEdit';
import { BiomarkerShow } from './BiomarkerShow';
import { BiomarkersList } from './BiomarkersList';

export default {
  list: BiomarkersList,
  show: BiomarkerShow,
  edit: BiomarkerEdit,
  create: BiomarkerCreate,
};
