import { CreateUnitDto, UnitsCoefficientsDto } from './../../api/generated/api';
export const unitValidator = (values: CreateUnitDto): Record<string, string> => {
  const errors: Record<string, any> = {};
  errors.unitCoefficients = [];
  if (!values?.name) {
    errors.name = 'Field is required';
  }

  // if (values?.unitCoefficients && values.unitCoefficients?.length > 0) {
  //   const selectedIds = values?.unitCoefficients?.map((item) => item?.compareUnitId);
  //   const isDuplicate = selectedIds.some((item, idx) => {
  //     if (selectedIds.indexOf(item) !== idx) {
  //       errors.unitCoefficients[idx] = 'Field id req';
  //       return true;
  //     }
  //   });
  // }

  return errors;
};

export const unitCoefficientsValidator = (record: Array<UnitsCoefficientsDto>): string | undefined => {
  const selectedIds = record?.map((item) => item?.compareUnitId).filter(Boolean);
  const isDuplicate = selectedIds?.some((item, idx) => selectedIds.indexOf(item) !== idx);
  if (isDuplicate) return 'Units must not repeat';
};
